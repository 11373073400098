<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-08-23 16:44:07
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-08-23 16:59:56
 * @FilePath: /mediatom-web/src/views/SDKDownload/DetailLogModal/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="logVisible"
    title="更新日志"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="500px"
  >
    <div class="log_box">
      <div class="log_item" v-for="(item, index) in logList" :key="index">
        <div class="log_head">
          <span class="version">版本{{ item.version }}</span>
          <span class="updated">{{ item.uploadTime }}</span>
        </div>
        <div class="log_content">
          <p v-for="(p, i) in item.modifyLogList" :key="i">
            {{ p }}
          </p>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { getSdkModifyLogByOs } from '@/api/sdkDownload'
export default {
  data () {
    return {
      logList: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    os: {
      default: undefined,
      type: Number
    }
  },
  watch: {
    visible: {
      async handler (val) {
        if (val) {
          const { data = {} } = await getSdkModifyLogByOs({ os: this.os })
          const { items = [] } = data
          this.logList = items
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    logVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCancel () {
      this.logVisible = false
    },
    handleSubmit () {
      this.logVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.log_box {
  height: 500px;
  flex-grow: 1;
  overflow-y: auto;
  .log_item {
    min-height: 120px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
    .log_head {
      color: #999999;
      font-size: 12px;
      line-height: 25px;
      .version {
        margin-right: 10px;
      }
    }
    .log_content {
      flex-grow: 1;
      p {
        margin-bottom: 3px;
        font-size: 13px;
        color: #333;
        line-height: 22px;
      }
    }
  }
}
</style>
