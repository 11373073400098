/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-08-23 12:03:50
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-08-23 16:31:09
 * @FilePath: /mediatom-web/src/api/sdkDownload.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
// 获取相应操作系统的sdk版本信息列表
export function getPublishedSdkList (data) {
  return request({
    url: '/accessCenter/getPublishedSdkList',
    method: 'post',
    data
  })
}
// 根据版本ID查询已绑定广告源
export function getSourceSdkAndPositionById (data) {
  return request({
    url: `/accessCenter/getSourceSdkAndPositionById`,
    method: 'post',
    data
  })
}

// 根据os查询medproSdk的更新日志
export function getSdkModifyLogByOs (data) {
  return request({
    url: `/accessCenter/getSdkModifyLogByOs`,
    method: 'post',
    data
  })
}

// 生成下载链接
export function getSdkDownLoadUrl (data) {
  return request({
    url: `/accessCenter/downloadSdk`,
    method: 'post',
    data
  })
}
